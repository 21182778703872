(function($) {
    $.widget('ogn.mapselector', {
        _create: function() {
            this.current = null;
            this.container = Snap('#map');
            Snap.load('/images/us-map.svg', this._onLoadSVG.bind(this));
            this.$selectInput = $('select', this.element);
            this.states = this._getStates();
            this.$selectInput.on('change', this._onChangeSelect.bind(this));
            this.$selectComponent = $('.component-select', this.element);
            this.$documents = $('.document-item', this.element);
            this.$currentDocument = null;
        },
        _getStates: function() {
            return this.$selectInput.children('option').map(function() {
                return { code: $(this).attr('value'), label: $(this).html() };
            }).get();
        },
        _getState: function(code) {
            return this.states.filter(function(state) {
                return state.code === code;
            })[0] || {};
        },
        _onLoadSVG: function(svg) {
            this.statesGroups = svg.selectAll('.state-group');
            this.statesGroups.forEach(this._addEventListeners.bind(this));
            this.container.append(svg);
        },
        _addEventListeners: function(element) {
            element.click(this._onClickState.bind(this, element));
            element.mouseover(this._onMouseOver.bind(this, element));
            element.mouseout(this._onMouseOut.bind(this, element));
        },
        _onClickState: function(element) {
            if (this.current !== element) {
                this._selectNewState(element);
                this.$selectComponent.trigger('change', [element.attr('id')]);
                this._toggleDocuments(element.attr('id'));
            }
        },
        _onMouseOver: function(element) {
            element.selectAll('.state-shape').forEach(function(el) {
                el.addClass('hover-shape');
            })
        },
        _onMouseOut: function(element) {
            element.selectAll('.state-shape').forEach(function(el) {
                el.removeClass('hover-shape');
            })
        },
        _selectNewState: function(element) {
            if (this.current !== element) {
                this._clearCurrent();
                var shapesElements = element.selectAll('.state-shape');
                var textsElements = element.selectAll('.state-text');
                shapesElements.forEach(function(el) {
                    el.addClass('active-shape');
                })
                textsElements.forEach(function(el) {
                    el.addClass('active-text');
                })
                this.current = element;
            }
        },
        _clearCurrent: function() {
            if (this.current) {
                var shapesElements = this.current.selectAll('.state-shape');
                var textsElements = this.current.selectAll('.state-text');
                shapesElements.forEach(function(shape) {
                    shape.removeClass('active-shape');
                })
                textsElements.forEach(function(text) {
                    text.removeClass('active-text');
                })
            }
        },
        _onChangeSelect: function(event) {
            var value = event.currentTarget.value;
            if (value && value.length) {
                var state = this.container.select('#' + value);
                if (state) {
                    this._selectNewState(state);
                }
            } else {
                this._clearCurrent();
                this.current = null;
            }
            this._toggleDocuments(value);
        },
        _toggleDocuments: function(state) {
            dataLayer.push({'event':'SELECT STATE OR TERRITORY', 'value': this._getState(state).label});
            if (this.$currentDocument) {
                this.$currentDocument.hide();
            }
            var $document = this.$documents.filter(function() {
                return $(this).data('state') === state;
            })
            if ($document.length) {
                $document.show();
                this.$currentDocument = $document;
            }
        }
    })
    $(document).ready(function() {
        $('.component-map').mapselector();
    });
})(jQuery);