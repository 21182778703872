(function($) {
    $.widget('ogn.chart', {
        _create: function() {
            this.$active = null;
            this.$items = $('.chart-item', this.element);
            this.$description = $('.description-holder', this.element);
            this.$carousel = $('.carousel', this.element);
            this.$control = $('.carousel-control-prev, .carousel-control-next', this.element);
            this.$close = $('.btn-close', this.element);
            this.$carousel.carousel({ interval: false });
        },
        _init: function() {
            this.$items.on('click touchend', this._onClickItem.bind(this));
            this.$close.on('click touchend', this._onClose.bind(this));
            this.$carousel.on('slid.bs.carousel', this._onEndSlide.bind(this));
        },
        _onClickItem: function(event) {
            event.preventDefault();
            console.log(event.currentTarget);
            var $element = $(event.currentTarget);
            if (this.$active) {
                this.$active.removeClass('active');
            }
            $element.addClass('active');
            this.$active = $element;
            this.$description.addClass('visible');
            this.$carousel.carousel($element.data('target'));
            return false;
        },
        _onClose: function() {
            this.$description.removeClass('visible');
            if (this.$active) {
                this.$active.removeClass('active');
                this.$active = null;
            }
        },
        _onEndSlide: function() {
            var active = $('.carousel-item.active', this.element).data('item');
            var $element = $(active, this.element);
            if (this.$active) {
                this.$active.removeClass('active');
            }
            $element.addClass('active');
            this.$active = $element;
        },
    });
    $(document).ready(function() {
        $('.component-circle-chart').chart();
    });
})(jQuery);