(function($) {
    $.widget( "ogn.navigation", {
        options: {
            'productsId': 'navigation-products-mobile',
            'mainId': 'navigation-general-mobile',
            'accordionId': 'navigation-accordion-mobile',
            'contactFormModalId': '#form-email-signup'
        },
        _create: function() {
            this.current = null;
            this.scrollOffsets = {
                mobile: 80,
                desktop: 80
            }
            this.layoutType = this._calculateLayoutType();
            this.navbarHeight = $('.navbar', this.element).height();
            this.menuHeight = $(this.window).height();
            this.bodyHeight = this.menuHeight - this.navbarHeight;
            this.menus = {};
            this.menus[this.options.productsId] = this._createProductsMobileMenu();
            this.menus[this.options.mainId] = this._createGeneralMobileMenu();
            this.scrollThreshold = 115;
        },

        _init: function() {
            this._onDocumentReady();
            this._on(this.document, {
                'touchend .navbar-menu-desktop .nav-general-item > .nav-link': function(event) {
                    var $element = $(event.currentTarget);
                    var hasSubmenu = $element.parent().find('ul').length > 0
                    if (hasSubmenu && !$element.hasClass('touched')) {
                        $element.addClass('touched');
                        return false;
                    }
                },
                'click .navbar-open': function(event) {
                    event.preventDefault();
                    this.menus[$(event.currentTarget).data().target].wrapper.trigger('open');
                },
                'click .navbar-close': function() {
                    if(this.current) {
                        this.current.trigger('close');
                    }
                },
                'click #mobile-contact-button': function() {
                    if (this.current) {
                        this.current.trigger('hide');
                    }
                    $(this.options.contactFormModalId).modal('show');
                },
                'open .navbar-menu-mobile': function(event) {
                    var id = event.target.id;
                    this.menus[id].wrapper.addClass('opened');
                    this.menus[id].wrapper.animate({
                        height: this.menuHeight
                    }, 200, function() {
                        $('body').addClass('disable-scrolling');
                    });
                    this.current = this.menus[id].wrapper;
                },
                'close .navbar-menu-mobile': function(event) {
                    var current = this.current;
                    this.current = null;
                    this.menus[event.target.id].wrapper.animate({
                        height: this.navbarHeight
                    }, 200, function() {
                        current.removeClass('opened');
                        $('body').removeClass('disable-scrolling');
                    });
                },
                'hide .navbar-menu-mobile': function(event) {
                    this.current.removeClass('opened');
                    $('body').removeClass('disable-scrolling');
                    this.menus[event.target.id].wrapper.height(this.navbarHeight);
                    this.current = null;
                }
            });
            this._on(this.window, {
                resize: function() {
                    if (this.current) {
                        this.current.trigger('hide');
                    }
                    this.layoutType = this._calculateLayoutType();
                    this._recalculateHeight();
                },
                scroll: function() {
                    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    if (scrollTop > this.scrollThreshold && !this.element.hasClass('small')) {
                        this.element.addClass('small');
                        this._recalculateHeight();
                    }
                    if (scrollTop < this.scrollThreshold && this.element.hasClass('small')) {
                        this.element.removeClass('small');
                        this._recalculateHeight();
                    }
                }
            });
        },

        _createProductsMobileMenu: function() {
            var $body = $('<ul>').addClass('navbar-menu-mobile-body').height(this.bodyHeight);
            $('.navbar-product-link' ,this.element).clone().each(function(index, product) {
                $('<li>').append(product).appendTo($body);
            });

            var $button = $('<button>')
                .addClass('navbar-close')
                .attr('type', 'button');

            $('<span>')
                .addClass('icon icon-close')
                .appendTo($button);

            var $heading = $('<div>')
                .addClass('navbar-menu-mobile-heading')
                .append('<span>Organogenesis Products</span>')
                .append($button);

            var $container = $('<div>')
                .addClass('container d-block')
                .append($heading)
                .append($body);
            var $wrapper = $('<div>')
                .addClass('navbar-menu-mobile')
                .attr('id', this.options.productsId)
                .append($container)
                .appendTo(this.element);
            return {
                wrapper: $wrapper,
                container: $container,
                body: $body,
            };
        },

        _createGeneralMobileMenu: function() {
            var $accordion = $('<div>').addClass('accordion').attr('id', this.options.accordionId);
            var accordionId = this.options.accordionId;
            $('.nav-general-item', this.element).each(function(index, item) {
                var $item = $(item);
                var $link = $(' > a', $item).clone();
                var $h2 = $('<h2>').append($link);
                var $heading = $('<div>').addClass('card-header').append($h2);
                var $card = $('<div>').addClass('card').append($heading);
                if ($item.has('ul').length) {
                    var headingId = 'heading-' + index;
                    var collapseId = 'collapse-' + index;
                    var $collapse = $('<div>')
                        .attr('id', collapseId)
                        .attr('aria-labelledby', headingId)
                        .attr('data-parent', '#' + accordionId)
                        .addClass('collapse menu-collapse')
                        .appendTo($card)
                        .on('shown.bs.collapse', function(event) {
                            $button.removeClass('icon-arrow-down').addClass('icon-arrow-up');
                        })
                        .on('hidden.bs.collapse', function() {
                            $button.removeClass('icon-arrow-up').addClass('icon-arrow-down');
                        });
                    $('<div>')
                        .addClass('card-body')
                        .append($(' > ul', $item).clone().prepend($('<li>').addClass('nav-item').append($link.clone().html('Overview'))))
                        .appendTo($collapse);
                    var $button = $('<button>')
                        .addClass('btn icon icon-arrow-down font-weight-bold')
                        .attr('type', 'button')
                        .appendTo($h2);
                    $h2.attr('data-toggle', 'collapse')
                        .attr('data-target', '#' + collapseId)
                        .attr('aria-expanded', 'true')
                        .attr('aria-controls', collapseId);
                    $heading.attr('id', headingId);
                    $link.attr('href', '#');
                }

                $accordion.append($card);
            });
            var $body = $('<div>')
                .addClass('navbar-menu-mobile-body')
                .height(this.bodyHeight)
                .append($accordion);

            $('<a>')
                .append($('<span>').addClass('icon icon-user font-weight-bold'))
                .append('GET IN CONTACT')
                .attr('href', '/contact')
                .appendTo($body);

            $('<a>')
                .append($('<span>').addClass('icon icon-envelope font-weight-bold'))
                .append('SIGN UP FOR EMAIL UPDATES')
                .attr('href', '#')
                .attr('id', 'mobile-contact-button')
                .appendTo($body);

            var $button = $('<button>')
                .addClass('navbar-close')
                .attr('type', 'button');

            $('<span>')
                .addClass('icon icon-close')
                .appendTo($button);

            var $heading = $('<div>')
                .addClass('navbar-menu-mobile-heading')
                .append('<span>Main Menu</span>')
                .append($button);

            var $container = $('<div>')
                .addClass('container d-block')
                .append($heading)
                .append($body);

            var $wrapper = $('<div>')
                .addClass('navbar-menu-mobile')
                .attr('id', this.options.mainId)
                .append($container)
                .appendTo(this.element);

            $accordion.collapse();
            return {
                wrapper: $wrapper,
                container: $container,
                body: $body,
            };
        },

        _recalculateHeight: function() {
            this.navbarHeight = $('.navbar', this.element).height();
            this.menuHeight = $(this.window).height();
            this.bodyHeight = this.menuHeight - this.navbarHeight;
            $('.navbar-menu-mobile-body', this.element).height(this.bodyHeight);
        },

        _calculateLayoutType: function() {
            return this.window.width() < 992 ? 'mobile' : 'desktop';
        },

        _onDocumentReady: function() {
            var scrollOffset = this.scrollOffsets[this.layoutType];
            this.document.ready(function() {
                if (window.location.hash && $(window.location.hash).length) {
                    var $anchor = $(window.location.hash);
                    setTimeout(function() {
                        $('html,body').scrollTop($anchor.offset().top - scrollOffset);
                    }, 200)
                }
            })
        }
    });
    $('#component-navigation').eq(0).navigation();
})(jQuery);
