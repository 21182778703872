(function($) {
    $.widget('ogn.tabs', {
        _create: function() {
            this.height = 0;
            this.$content = $('.tab-content', this.element);
            this.hasContent = this.$content.length === 1;
            this.$containers = $('.tab-pane', this.element);
            this.hasContainers = this.$containers.length > 0;
        },
        _init: function() {
            this.window.on('load', this._onHeightUpdate.bind(this));
            this.window.on('resize', this._onHeightUpdate.bind(this));
        },
        _onHeightUpdate: function() {
            if (this.hasContent && this.hasContainers && this.window.width() > 991) {
                this.$containers.each(this._max.bind(this));
                this.$content.height(this.height);
            }
        },
        _max: function(index, element) {
            this.height = Math.max(this.height, $(element).outerHeight())
        }
    })
    $('.component-tabs').tabs();
})(jQuery);