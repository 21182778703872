(function($) {
    scrollController = new ScrollMagic.Controller({
        //addIndicators: true
    });
    $.widget('ogn.parallaxcomp', {
        _create: function() {
            this.currentWidth = this.window.width();
            this.breakpoint = this._calcBreakPoint();
            this.background = $('.background-holder .container', this.element);
            this.content = $('.content-holder', this.element);
            this.content.height(this._calcContentHeight(this.breakpoint));
            this.scene = new ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: this._calcTriggerHook(),
                duration: this._calcDuration(this.breakpoint),
            })
                .setPin(this.element, {pushFollowers: false})
                .setTween(this._calcBackgroundTween(this.breakpoint))
                .addTo(scrollController);
        },
        _init: function() {
            this.window.on('resize', this._onWindowResize.bind(this));
        },
        _calcTriggerHook: function() {
            return offsetNavigationHeight / this.window.height();
        },
        _calcDuration: function(breakpoint) {
            var percent = 1 - this._calcContentHeightPercent(breakpoint);
            return this.element.height() * percent;
        },
        _calcBackgroundTween: function(breakpoint) {
            function lerp(start, end, t) {
                return start * (1 - t) + end * t
            }
            var tween;
            switch(breakpoint) {
                case 'xs':
                    var step = ((this.window.width() - 320) / 255);
                    var percent = Math.floor(lerp(5, 27, step));
                    tween = TweenMax.fromTo(this.background, {y: '0'}, {y: '-' + percent + '%', ease: Linear.easeNone });
                    break;
                case 'sm':
                    tween = TweenMax.fromTo(this.background, {y: '0'}, {y: '-29%', ease: Linear.easeNone });
                    break;
                default:
                    tween = TweenMax.fromTo(this.background, {y: '0'}, {y: '-26%', ease: Linear.easeNone });
            }
            return new TimelineMax ()
                .add(tween);
        },
        _calcBreakPoint: function() {
            var breakpoint = 'xs';
            if (this.window.width() >= 576) {
                breakpoint = 'sm';
            }
            if (this.window.width() >= 768) {
                breakpoint = 'md';
            }
            if (this.window.width() >= 992) {
                breakpoint = 'lg';
            }
            if (this.window.width() >= 1200) {
                breakpoint = 'xl';
            }
            return breakpoint;
        },
        _calcContentHeightPercent: function(breakpoint) {
            function lerp(start, end, t) {
                return start * (1 - t) + end * t
            }
            if (breakpoint === 'xs') {
                var step = ((this.window.width() - 320) / 255);
                return Math.floor(lerp(75, 50, step)) / 100;
            } else {
                return 0.5;
            }
        },
        _calcContentHeight: function(breakpoint) {
            var percent = this._calcContentHeightPercent(breakpoint);
            return this.element.height() * percent;
        },
        _onWindowResize: function() {
            var breakpoint = this._calcBreakPoint();
            var isSceneUpdateRequired = (breakpoint === 'xs' || (breakpoint !== this.breakpoint));
            if (isSceneUpdateRequired) {
                this.content.height(this._calcContentHeight(breakpoint));
                this.scene.duration(this._calcDuration(breakpoint));
                this.scene.removeTween(true);
                this.scene.setTween(this._calcBackgroundTween(breakpoint));
            }
            this.scene.triggerHook(this._calcTriggerHook());
            this.scene.update(true);
            this.element.css('width', this.window.width()).parent().css('min-width', this.window.width());
            this.scene.refresh();
            this.breakpoint = breakpoint;
            this.currentWidth = this.window.width();
        },
    });
    $.widget('ogn.parallaxelem', {
        _create: function() {
            var configs = {
                triggerElement: this.element,
                triggerHook: this.element.data('toggle') || 0.7,
            }
            new $.ScrollMagic.Scene(configs).setClassToggle(this.element, 'visible').addTo(scrollController);
        },
    });
    $(document).ready(function() {
        $('.template-home .parallax-animated-element').parallaxelem();
        $('.template-home .parallax-animated-component').parallaxcomp();
    });
})(jQuery);