$(function() {
	var Plugin = videojs.getPlugin('plugin');
	var ChaptersPlugin = videojs.extend(Plugin, {
		constructor: function(player, options) {
			Plugin.call(this, player, options);

			player.on('loadedmetadata', this._onLoadMetadata.bind(this));
			player.on('resize', this._onResize.bind(this));
			player.on('playerresize', this._onResize.bind(this));
		},
		_onResize: function () {
			var parentHeight = $(this.player.el()).outerHeight();
			var controlHeight = $(this.player.controlBar.el()).height();
			if (parentHeight && controlHeight && parentHeight > controlHeight)  {
				$(this.player.controlBar.chaptersButton.menu.contentEl()).css('max-height', (parentHeight - (controlHeight + 10)) + 'px');
			}
		},
		_onLoadMetadata: function() {
			var cuesAra = [],
			chapters = [],
			chapterTT = [].filter.call(
				this.player.textTracks(),
				function(tt) {
					return tt.kind === "chapters";
				}
			);
			if (chapterTT[0]) {
				cuesAra = chapterTT[0].cues;
				
				for (var i = 0; i < cuesAra.length; i++) {
					chapters[i] = {
						start: cuesAra[i].startTime,
						text: cuesAra[i].text
					};
				}
				this._addMarkers(chapters);
			}
		},
		_addMarkers: function (chapters) {
			var videoDuration = this.player.duration();
			var playheadWell = document.getElementsByClassName("vjs-progress-control vjs-control")[0];
			var markers = [];
			var $items = $('.vjs-chapters-button .vjs-menu .vjs-menu-content .vjs-menu-item');
			for (var i = 0; i < chapters.length; i++) {
				var marker = document.createElement("div");
				marker.className = "vjs-marker";
				marker.id = 'cp' + i;
				marker.dataset.id = i;
				marker.style.left = chapters[i].start / videoDuration * 100 + "%";
				playheadWell.appendChild(marker);
				var text = document.createElement('span');
				text.innerHTML = chapters[i].text;
				marker.appendChild(text);
				markers.push(marker);
				$items.eq(i).data('id', i);
				$(marker).on({
					mouseover: function() {
						$items.removeClass('vjs-marker-hovered');
						$items.eq(this.dataset.id).addClass('vjs-marker-hovered');
					},
					mouseleave: function () {
						$items.eq(this.dataset.id).removeClass('vjs-marker-hovered');
					}
				});
			}
			$items.on({
				mouseover: function() {
					$(markers[$(this).data('id')]).addClass('vjs-marker-hovered');
				},
				mouseleave: function() {
					$(markers[$(this).data('id')]).removeClass('vjs-marker-hovered');
				}
			})
		}
	});
	videojs.registerPlugin('chapters', ChaptersPlugin);
	//
	if ($('#moa-video').length) {
		var player = videojs('moa-video', {
			html5: {
				nativeTextTracks: false
			},
			controls: true,
			controlBar: {
				pictureInPictureToggle: false,
				chaptersButton: true
			},
			preload: 'auto',
			width: 848,
			height: 477,
		});
		player.chapters();
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'INNOVATION THAT WON\'T BE IGNORED', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'INNOVATION THAT WON\'T BE IGNORED', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'INNOVATION THAT WON\'T BE IGNORED', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'INNOVATION THAT WON\'T BE IGNORED', 'value':'COMPLETE'});
		});
	}

	//
	if ($('#video-heor').length) {
		var player = videojs('video-heor');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'HEOR VIDEO', 'value':'START'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'HEOR VIDEO', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'HEOR VIDEO', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'HEOR VIDEO', 'value':'COMPLETE'});
		});
	}

	//
	if ($('#video-vlu-costs').length) {
		var player = videojs('video-vlu-costs');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'VLU VIDEO', 'value':'START'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'VLU VIDEO', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'VLU VIDEO', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'VLU VIDEO', 'value':'COMPLETE'});
		});
	}

	//
	if ($('#video-dfu-apply-instructions').length) {
		var player = videojs('video-dfu-apply-instructions');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'Diabetic foot ulcer application', 'value':'START'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'Diabetic foot ulcer application', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'Diabetic foot ulcer application', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'Diabetic foot ulcer application', 'value':'COMPLETE'});
		});
	}

	//
	if ($('#video-vlu-apply-instructions').length) {
        var player = videojs('video-vlu-apply-instructions');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'Venous leg ulcer application', 'value':'START'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'Venous leg ulcer application', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'Venous leg ulcer application', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'Venous leg ulcer application', 'value':'COMPLETE'});
		});
	}

	var Button = videojs.getComponent('Button');
	var skipButton = videojs.extend(Button, {
		constructor: function(player, options) {
			Button.call(this, player, options);
			this.player = player;
			this.player.on('timeupdate', this._onPlayerTimeUpdate.bind(this, player));
			this.skipTime = options.skipTime;
			if (!this.skipTime) {
				this.skipTime = 0;
			}
		},
		createEl: function(tag, props, attributes) {
			if (typeof props !== 'object' || props == null) {
				props = {};
			}
			if (typeof attributes !== 'object' || attributes == null) {
				attributes = {};
			}
			props.innerHTML = '<span>Skip intro</span><i class="icon font-weight-bold icon-angle-double-right"></i>';
			props.className = 'video-instructions-skip-button';
			attributes.type = 'button';
			return Button.prototype.createEl('button', props, attributes);
		},
		handleClick: function() {
			this.player.currentTime(this.skipTime)
		},
		_onPlayerTimeUpdate: function(player) {
			var time = player.currentTime();
			if (time > 1 && time < this.skipTime - 1) {
				this.addClass('visible');
				this.enable();
			}
			if (time > this.skipTime) {
				this.removeClass('visible');
				this.disable();
			}
		}
	});
	videojs.registerComponent('Skip', skipButton);
	if ($('#video-dfu-apply-instructions').length) {
		var player = videojs('video-dfu-apply-instructions');
		var controlBar = player.getChild('ControlBar');
		controlBar.addChild('Skip', {
			skipTime: 64
		});
	}
	if ($('#video-vlu-apply-instructions').length) {
		var player = videojs('video-vlu-apply-instructions');
		var controlBar = player.getChild('ControlBar');
		controlBar.addChild('Skip', {
			skipTime: 80
		});
	}
});