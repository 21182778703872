(function($){
    $.widget("ogn.instructions", {
        _create: function() {
            this.$contentItems = $('.aligned-to-images .content-item', this.element);
            this.$imageItems = $('.aligned-to-images .image-holder', this.element);
            this._calculate();
        },
        _init: function() {
            this.window.on('resize', this._calculate.bind(this));
        },
        _calculate: function() {
            this.$imageItems.each(this._process.bind(this));
        },
        _process: function(index, image) {
            var minHeight = 'auto';
            var marginBottom = 24;
            var $image = $(image);
            var $content = this.$contentItems.eq(index);
            $image.css('margin-bottom', '');
            $content.css('min-height', '');
            var bp = breakpoint();
            if (bp === 'md' || bp === 'lg' || bp === 'xl') {
                var imageHeight = $image.height();
                var contentHeight = $content.height();
                minHeight = imageHeight > contentHeight ? imageHeight : contentHeight;
                marginBottom = (minHeight - imageHeight) + 24;
                $image.css('margin-bottom', marginBottom);
                $content.css('min-height', minHeight);
            }
        }
    })
    $(document).ready(function() {
        $('.component-instructions-for-use').instructions();
    });
    var breakpoint = function() {
        var width = $(window).width();
        var breakpoint = 'xs';
        if (width >= 576) {
            breakpoint = 'sm';
        }
        if (width >= 768) {
            breakpoint = 'md';
        }
        if (width >= 992) {
            breakpoint = 'lg';
        }
        if (width >= 1200) {
            breakpoint = 'xl';
        }
        return breakpoint;
    };
})(jQuery);